import {
	conversationsApi,
	conversationsAuthApi,
	inquireAPIUrl,
} from '@settings';
import type { Member } from '@typings/membership';
import { Logger } from '@utils/logger/logger';
import axios from 'axios';

export async function getJwtAuth(
	basicHeaders: object,
	membershipSessionToken: string,
) {
	try {
		const authResponse = await axios.post(
			conversationsAuthApi,
			{},
			{
				headers: {
					...basicHeaders,
					'membership-session-token': membershipSessionToken,
				},
			},
		);

		return authResponse.data;
	} catch (e) {
		// ignoring membership errors (eg. expired token)
	}

	return undefined;
}

export default async function conversations(
	memberId: Member['id'] | undefined,
	membershipSessionToken: string,
) {
	const basicHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
	const jwtAuth = await getJwtAuth(basicHeaders, membershipSessionToken);

	if (jwtAuth) {
		const response = await axios.get(`${conversationsApi}/${memberId}`, {
			headers: {
				...basicHeaders,
				Authorization: `Bearer ${jwtAuth}`,
			},
		});

		return response.data.conversations;
	}

	return {};
}

export interface StartInboxMessageParams {
	storefrontId: string | null;
	vendorMarketCode: string | null;
	vendorCategoryCode: string | null;
	memberId: string | undefined;
	sessionToken: string;
	constructedMessage: string;
	platform: string;
}

export const startInboxMessage = async ({
	storefrontId,
	vendorMarketCode,
	vendorCategoryCode,
	memberId,
	sessionToken,
	constructedMessage,
	platform,
}: StartInboxMessageParams) => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	try {
		const token = await getJwtAuth(headers, sessionToken);
		if (!token) {
			throw new Error('Failed to get JWT token');
		}

		const options: RequestInit = {
			method: 'POST',
			body: JSON.stringify({
				body: constructedMessage,
				storefrontId: storefrontId,
				source: {
					application: 'theknot',
					marketCode: vendorMarketCode,
					categoryCode: vendorCategoryCode,
					platform: platform,
				},
				senderInfo: {
					id: memberId,
				},
			}),
			headers: {
				...headers,
				Authorization: `Bearer ${token}`,
			},
		};

		const response = await fetch(inquireAPIUrl, options);

		return response;
	} catch (error) {
		Logger.error({
			log_message: 'Start Inbox Message API call failed',
			errorToLog: error,
			newRelic: true,
		});
		throw error;
	}
};

export interface SendInboxMessageParams {
	vendorId: string | null;
	memberId: string | undefined;
	sessionToken: string;
	constructedMessage: string;
	platform: string;
}

export const sendInboxMessage = async ({
	vendorId,
	memberId,
	sessionToken,
	constructedMessage,
	platform,
}: SendInboxMessageParams) => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
	try {
		const token = await getJwtAuth(headers, sessionToken);
		if (!token) {
			throw new Error('Failed to get JWT token');
		}

		const options: RequestInit = {
			method: 'POST',
			body: JSON.stringify({
				body: constructedMessage,
				recipients: [vendorId],
				source: {
					application: 'theknot',
					platform: platform,
				},
				senderInfo: {
					id: memberId,
				},
			}),
			headers: {
				...headers,
				Authorization: `Bearer ${token}`,
			},
		};

		const response = await fetch(`${conversationsApi}/message`, options);

		return response;
	} catch (error) {
		Logger.error({
			log_message: 'Send Inbox Message API call failed',
			errorToLog: error,
			newRelic: true,
		});
		throw error;
	}
};
